import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import hatches from '@/store/hatches'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

/* eslint-disable */
const app = createApp(App)
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://2f3fb5abea814916a4a0e6a3cb4d8d63@o455520.ingest.sentry.io/5526439',
    integrations: [new VueIntegration({ Vue: app }), new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
app.provide('hatches', hatches)
app.use(router)
app.mount('#app')
