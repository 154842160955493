<template>
  <div class="hatch-hole" data-testid="hatch" :class="{ opened: contents.is_past }">
    <transition enter-active-class="animated" leave-active-class="animated flipOutY" mode="out-in">
      <div v-if="!open" class="hatch-door" @click="openHatch(contents)">
        <span>{{ contents.day }}</span>
      </div>
    </transition>
    <div class="hatch-bg absolute grid place-items-center -z-1 w-full h-full inset-0">
      <img
        v-if="contents.image"
        :src="contents.image.thumbnail"
        class="w-full h-full object-cover"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import router from '@/router'
export default {
  name: 'HatchClosed',
  props: {
    contents: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  setup() {
    let open = ref(false)
    let hatchState = inject('hatches')

    const openHatch = (hatch) => {
      if (hatch.is_past) {
        if (process.env.NODE_ENV === 'production') {
          window.dataLayer.push({
            paiva: hatch.day,
            event: 'avaaLuukku',
          })
        }
        hatchState.openHatch({ day: hatch.day })
        open.value = !open.value
        setTimeout(() => {
          router.push({ name: 'open-hatch' })
        }, 1000)
      }
    }

    function goToUrl(url) {
      window.location = url
    }
    return { open, goToUrl, openHatch }
  },
}
</script>
<style lang="scss">
.hatch-hole {
  @apply w-full relative;
  height: 0;
  padding-bottom: 100%;
  perspective: 100vw;
  .hatch-bg {
    @apply opacity-0 text-gunmetal;
    background: #d1cfcb;
    box-shadow: inset 5px 8px 4px #938d82;
  }
  .hatch-door {
    @apply w-full h-full flex items-center justify-center select-none absolute inset-0 z-1 rounded;
    transform-origin: left;
    transition: transform 300ms;
    > span {
      @apply font-display font-bold text-28 pointer-events-none;
    }
  }
  &.opened {
    transition: transform 500ms;
    &:hover {
      transform: scale(1.02);
    }
    .hatch-door:hover {
      @apply bg-white-a30 border border-gunmetal cursor-pointer;
    }
    .hatch-door.animated {
      @apply bg-white shadow-lg;
      + .hatch-bg {
        @apply opacity-100;
      }
    }
  }
}
</style>
