<template>
  <header class="calendar-header py-5 text-center">
    <div
      class="container items-center flex-wrap flex-col flex lg:flex-row xl:flex-col gap-y-5 gap-x-8"
    >
      <a href="https://www.veke.fi" class="block">
        <img
          src="@/veke-ui/assets/logo/veke-logo.svg"
          width="200"
          height="43"
          alt="Veke"
          class="inline-block w-24 md:w-32 xl:w-24"
        />
      </a>
      <h1>Sisustajan joulukalenteri</h1>
    </div>
  </header>
  <div class="container my-5">
    <calendar-root v-if="isDecember" />
    <count-down v-else />
  </div>
  <footer v-if="isDecember" class="mt-16 mb-8 w-full text-center">
    <div class="font-handwritten">
      <span class="text-36">Inspiroivaa Joulua!</span><br />
      <span class="text-24">t: Veken väki</span>
    </div>
  </footer>
</template>

<script>
import CalendarRoot from '@/components/calendar/CalendarRoot.vue'
import CountDown from '@/components/CountDown'

export default {
  name: 'Home',
  components: {
    CalendarRoot,
    CountDown,
  },
  computed: {
    isDecember: () => new Date().getMonth() === 11,
  },
}
</script>
