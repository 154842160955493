<template>
  <div class="calendar text-center">
    <div
      v-if="state.hatches_loaded"
      class="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-8 place-items-center"
    >
      <transition-group enter-active-class="animated fadeIn zoomIn" appear>
        <hatch-closed
          v-for="(hatch, i) in state.hathces"
          :key="i"
          class=""
          :contents="hatch"
          :index="i"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import HatchClosed from '@/components/calendar/sub/HatchClosed'
import { inject } from 'vue'
export default {
  name: 'CalendarRoot',
  components: { HatchClosed },
  setup() {
    let hatchState = inject('hatches')
    hatchState.shuffleHatches()
    return { state: hatchState.state }
  },
}
</script>
