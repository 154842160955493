<template>
  <section>
    <div class="container max-w-screen-md">
      <article class="bg-white rounded p-container">
        <h3 class="mb-4">Joulukalenterimme aukeaa taas joulukuussa!</h3>
        <p>Kurkkaa sillä välin tarjouksemme!</p>
        <a
          class="btn btn-primary w-full sm:w-auto mt-4"
          href="https://www.veke.fi/vinkit/kampanjat"
        >
          Katso tarjoukset
        </a>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CountDown',
}
</script>
