import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import OpenHatch from '@/views/OpenHatch'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/avaa-luukku',
    name: 'open-hatch',
    component: OpenHatch,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name === 'open-hatch' && from.name === undefined) {
    // Redirect user to homepage
    return next({ path: '/' })
  }
  // Let the user pass
  return next()
})

export default router
