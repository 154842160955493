import { reactive, readonly } from 'vue'
import _ from 'lodash'
import contentful from '@/plugins/contentful/client.js'
import { ravenException } from '../plugins/raven/ravenPlugin'

/* eslint-disable */
const state = reactive({
  hathces: [
    {
      day: 1,
      is_past: false,
    },
    {
      day: 2,
      is_past: false,
    },
    {
      day: 3,
      is_past: false,
    },
    {
      day: 4,
      is_past: false,
    },
    {
      day: 5,
      is_past: false,
    },
    {
      day: 6,
      is_past: false,
    },
    {
      day: 7,
      is_past: false,
    },
    {
      day: 8,
      is_past: false,
    },
    {
      day: 9,
      is_past: false,
    },
    {
      day: 10,
      is_past: false,
    },
    {
      day: 11,
      is_past: false,
    },
    {
      day: 12,
      is_past: false,
    },
    {
      day: 13,
      is_past: false,
    },
    {
      day: 14,
      is_past: false,
    },
    {
      day: 15,
      is_past: false,
    },
    {
      day: 16,
      is_past: false,
    },
    {
      day: 17,
      is_past: false,
    },
    {
      day: 18,
      is_past: false,
    },
    {
      day: 19,
      is_past: false,
    },
    {
      day: 20,
      is_past: false,
    },
    {
      day: 21,
      is_past: false,
    },
    {
      day: 22,
      is_past: false,
    },
    {
      day: 23,
      is_past: false,
    },
    {
      day: 24,
      is_past: false,
    },
  ],
  hatches_loaded: false,
  hatch_open: false,
  opened_hatch_day: null,
})

const setHatches = function(payload) {
  state.hathces = payload
}

const openHatch = (payload) => {
  state.opened_hatch_data = state.hathces.filter((hatch) => hatch.day === payload.day)[0]
}

const setHatchesLoaded = () => {
  state.hatches_loaded = true
}

const shuffleHatches = () => {
  if (!state.hatches_loaded) {
    contentful
      .getEntries({ content_type: 'christmasCalendarHatch' })
      .then((response) => {
        const currentDate = new Date().getDate();
        response.items.forEach((item) => {
          let dayNumber = item.fields.dayNumber
          // loop all hatches and set data for correct day
          state.hathces.forEach((hatch) => {
            if (hatch.day === dayNumber && currentDate >= dayNumber) {
              hatch.image = {
                src: 'https:' + item.fields.imageForDay.fields.file.url,
                thumbnail: 'https:' + item.fields.imageForDay.fields.file.url + '?h=240'
              }
              hatch.url = item.fields.urlForTheDay ?? null
              hatch.click_text = item.fields.clickText ?? null
              hatch.title = item.fields.titleForDay
              hatch.info = item.fields.info
              hatch.is_past = true
            }
          })
        })
        const shuffled = _.shuffle(state.hathces)
        setHatches(shuffled)
        setHatchesLoaded()
      })
      .catch((err) => {
        ravenException(err)
      })
  }
}

export default { state: readonly(state), shuffleHatches, openHatch }
