<template>
  <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeIn" appear>
    <section>
      <div class="container max-w-screen-md">
        <header class="text-center py-5 flex items-center justify-center relative">
          <router-link
            to="/"
            class="btn btn-tertiary theme-lavender absolute left-0"
            title="Takaisin"
            ><arrow-left-icon size="32"
          /></router-link>
          <a href="https://www.veke.fi" class="block">
            <img
              src="@/veke-ui/assets/logo/veke-logo.svg"
              width="200"
              height="43"
              alt="Veke"
              class="inline-block w-24"
            />
          </a>
        </header>
      </div>
      <div class="container max-w-screen-md" data-testid="hatch-open">
        <div class="bg-white rounded shadow-lg">
          <div class="bg-snow" style="aspect-ratio: 1.5">
            <img :src="state.opened_hatch_data.image.src" alt="" />
          </div>
          <article class="p-5">
            <div class="font-display text-muted uppercase font-bold tracking-wide text-12 mb-2">
              Luukku {{ state.opened_hatch_data.day }}
            </div>
            <h2 class="gamma mb-4 mt-0">{{ state.opened_hatch_data.title }}</h2>
            <div class="whitespace-pre-line" v-html="parseInfoText(state.opened_hatch_data.info)" />
            <button
              v-if="state.opened_hatch_data.click_text"
              @click="openUrl(state.opened_hatch_data)"
              class="btn w-full mt-5 text-12 xs:text-16"
            >
              {{ state.opened_hatch_data.click_text }}
            </button>
          </article>
        </div>
        <div class="my-5">
          <router-link to="/" class="btn btn-tertiary theme-lavender w-full">Takaisin</router-link>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { inject, onMounted } from 'vue'
import { ArrowLeftIcon } from '@bit/vekenkaluste.veke-ui.icons'
export default {
  name: 'HatchOpen',
  components: { ArrowLeftIcon },
  setup() {
    let hatchState = inject('hatches')

    onMounted(() => {
      window.scrollTo(0, 0)
    })

    const renderers = {
      renderNode: {
        [INLINES.HYPERLINK]: (node, next) => {
          // Open links in a new tab
          return `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`
        },
      },
    }

    const parseInfoText = (data) => {
      return documentToHtmlString(data, renderers)
    }

    const openUrl = (data) => {
      if (data.url) {
        if (process.env.NODE_ENV === 'production') {
          window.dataLayer.push({
            paiva: data.day,
            event: 'klikkaaCTA',
          })
        }
        // window.location = data.url
        window.open(data.url, '_blank')
      }
    }
    return { state: hatchState.state, parseInfoText, openUrl }
  },
}
</script>
