import Raven from 'raven-js'
// /* eslint-disable */
Raven.config('https://2f3fb5abea814916a4a0e6a3cb4d8d63@o455520.ingest.sentry.io/5526439', {
  environment: process.env.NODE_ENV,
})

const ravenException = (payload) => {
  // if payload is inserted, add it as context
  if (payload.context !== undefined) {
    Raven.setExtraContext(payload.context)
    Raven.captureException(payload.error)
  } else {
    Raven.captureException(payload)
  }
}

export { ravenException }
