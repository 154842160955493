<template>
  <div>
    <hatch-open></hatch-open>
  </div>
</template>

<script>
import HatchOpen from '@/components/calendar/HatchOpen'
export default {
  name: 'OpenHatch',
  components: { HatchOpen },
}
</script>
