<template>
  <router-view />
  <div class="calendar-background"></div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss" src="UI/scss/styles.scss"></style>
<style lang="scss">
#app {
  .calendar-background {
    @apply fixed h-screen opacity-50 transition-opacity inset-x-0 bottom-0 bg-cover bg-no-repeat;
    width: 100%;
    z-index: -1;
    background-image: url('./assets/images/2021-bg-mobile.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (orientation: landscape) {
      background-image: url('./assets/images/2021-bg-desktop.jpg');
    }
    @media (orientation: landscape) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
      background-image: url('./assets/images/2021-bg-desktop_x2.jpg');
    }
  }
}
</style>
